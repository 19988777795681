const navMobile = document.querySelector('.nav-mobile__items')
const navMobileBtn = document.querySelector('.nav-mobile__btn')
const allNavItems = document.querySelectorAll('.nav-mobile__item')

const header = document.querySelector('.header-desktop')
const cards = document.querySelectorAll('.header-desktop__card')

const menuItems = document.querySelectorAll('.nav-desktop__item')
const scrollSpySections = document.querySelectorAll('.section')
const lastOfType = document.querySelector('.nav-desktop__item:last-of-type')

const footerYear = document.querySelector('.footer__year')

// NAV MOBILE
const handleNav = () => {
	navMobile.classList.toggle('nav-mobile__items--active')
	navMobileBtn.classList.toggle('nav-mobile__btn--active')

	allNavItems.forEach(item => {
		item.addEventListener('click', () => {
			navMobile.classList.remove('nav-mobile__items--active')
			navMobileBtn.classList.remove('nav-mobile__btn--active')
		})
	})
}

// HEADER DESKTOP
function showCard() {
	cards.forEach(card => {
		card.classList.remove('header-desktop__active')
		this.classList.add('header-desktop__active')
	})
}

// SCROLLSPY
const handleScrollSpy = () => {
	if (document.body.classList.contains('main-page')) {
		const sections = []

		scrollSpySections.forEach(section => {
			if (window.scrollY <= section.offsetTop + section.offsetHeight - 50) {
				sections.push(section)

				const activeSection = document.querySelector(`[class*="nav-desktop__item"][href*="${sections[0].id}"]`)

				menuItems.forEach(item => item.classList.remove('nav-desktop__active'))

				activeSection.classList.add('nav-desktop__active')
			}

			if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 500) {
				menuItems.forEach(item => item.classList.remove('nav-desktop__active'))

				lastOfType.classList.add('nav-desktop__active')

				console.log(lastOfType)
			}
		})
	}
}

// FOOTER YEAR

const hanleCurrentYear = () => {
	const year = new Date().getFullYear()
	footerYear.innerText = year
}

navMobileBtn.addEventListener('click', handleNav)


cards.forEach(card => card.addEventListener('click', showCard))

window.addEventListener('scroll', handleScrollSpy)

hanleCurrentYear()
